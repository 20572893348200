import * as React from 'react'

type Props = {
    highlighted?: boolean
} & React.HTMLAttributes<HTMLDivElement>

export const MenuItem:React.FC<Props> = ({children, onClick, highlighted}: Props) => {

    const highlightStyle = highlighted ? 'text-primary' : '';
    return <div className={`flex space-x-2 p-2 cursor-pointer hover:text-primary hover:opacity-50 ${highlightStyle}`} onClick={onClick}>
        {children}
    </div>
}