import { PubMedArticle } from "@medscrib-core/PubMedArticle"
import * as moment from "moment"
import * as React from "react"
import { TextContent } from "./TextContent"
import { BookmarkIcon } from "@sleekly-ui/icons"
import { AuthorsList } from "./AuthorsList"
import { JournalName } from "./JournalName"
import { ExpandIcon } from "@sleekly-ui/icons/ExpandIcon"

type Props = {
    selected: boolean
    article: PubMedArticle,
    isExcluded: boolean,
    isIncluded: boolean,
    onOpenArticle: (article: PubMedArticle) => void
} & React.HTMLAttributes<HTMLDivElement>

export const ArticleTile: React.FC<Props> = ({ article, selected, isExcluded, isIncluded, onOpenArticle }: Props) => {
    const revisedDate = moment(article.revised_date).format('DD/MM/YYYY');
    const articleDate = moment(article.article_date).format('DD/MM/YYYY');
    
    const selectedStyle = selected ? 'bg-primary rounded-md bg-opacity-10' : '';
    const excludedStyle = isExcluded ? 'opacity-20' : '';

    return <div className={`my-1 border-b border-base-content border-opacity-25 cursor-pointer`}>
        <div className={`p-2 mb-2 cursor-pointer ${selectedStyle} ${excludedStyle}`}
            onClick={(e) => onOpenArticle(article)}>
            <div className="flex">
                <TextContent className="font-semibold" text={article.title}></TextContent>
                {
                    isIncluded && <>
                        <div className="flex-1"></div>
                        <div>
                            <BookmarkIcon className="text-primary"></BookmarkIcon>
                        </div>
                    </>
                }
            </div>
            <JournalName journal={article.journal}></JournalName>
            <AuthorsList authors={article.authors}></AuthorsList>
            <div className="text-xs opacity-75 mt-1">Art. Date: {articleDate} - Rev. Date: {revisedDate}</div>
        </div>
    </div>
}