import * as React from "react";

import { AlchemixFlux } from "../alchemix/Alchemix";

import { List } from '@sleekly-ui/components/List';
import { ScreenTitle } from '@sleekly-ui/components/ScreenTitle';

import { SearchResultsPage } from "@medscrib-core/SearchResultsPage";
import { GetResultsPage } from "@medscrib-core/GetResultsPage";

import { PubMedArticle } from "@medscrib-core/PubMedArticle";
import { ExcludeArticle } from "@medscrib-core/ExcludeArticle";
import { IncludeArticle } from "@medscrib-core/IncludeArticle";
import { ProjectArticles } from "@medscrib-core/ProjectArticles";

import { ArticleTile } from "../article/ArticleTile";
import { ArticleScreen } from "../article/ArticleScreen";
import { Project } from "@medscrib-core/Project";

import * as moment from "moment";
import { LoadProjectArticles } from "@medscrib-core/LoadProjectArticles";
import { generateUUID } from "@sleekly-ui/utils/uuid";
import { SearchResultsSummary } from "@medscrib-core/SearchResultsSummary";
import { ResetIcon } from "@sleekly-ui/icons";
import { SelectionAction } from "../article/TextContent";


type Props = {
    project: Project,
    searchResults: SearchResultsSummary,
    loading: boolean,
    onRefreshSearch: () => void
    onRefineSearch: (terms: string) => void
} & React.HTMLAttributes<HTMLDivElement>

export const SearchResultsExplorer: React.FC<Props> = ({ searchResults, project, loading, onRefreshSearch,onRefineSearch }: Props) => {

    const [currentArticle, setCurrentArticle] = React.useState<PubMedArticle>(null);

    const [projectArticles, setProjectArticles] = React.useState<ProjectArticles>(null);

    const [currentPage, setCurrentPage] = React.useState<SearchResultsPage>(null);

    const [loadingResults, setLoadingResults] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (project) {
            loadProjectArticles();
        }
        setCurrentArticle(null);
    }, [project]);

    React.useEffect(() => {
        if (loading) {
            setCurrentArticle(null);
        }
    }, [loading]);

    React.useEffect(() => {
        if (searchResults) {
            loadPageContent(searchResults, 0);
        }
    }, [searchResults]);


    const loadPageContent = async (searchResults: SearchResultsSummary, page_number: number) => {
        setLoadingResults(true);
        const search: GetResultsPage = {
            id: generateUUID(),
            kind: 'GetResultsPage',
            project_id: searchResults.project_id,
            search_id: searchResults.id,
            page_number
        };

        const res = await AlchemixFlux.postEvent("medscrib", search);
        console.log("loadPageContent : ", res);
        const results: SearchResultsPage = res.getFirst("SearchResultsPage");
        setCurrentPage(results)
        setLoadingResults(false);
    }

    const visibleDocuments = currentPage ? currentPage.articles : [];

    const articleTileRenderer = (onOpenArticle, currentArticle, projectArticles: ProjectArticles) => (article: PubMedArticle) => {
        let isExcluded = isArticleExluded(article, projectArticles);
        let isIncluded = isArticleIncluded(article, projectArticles);
        return <ArticleTile
            selected={currentArticle?.id === article.id}
            onOpenArticle={onOpenArticle}
            article={article}
            isExcluded={isExcluded}
            isIncluded={isIncluded}></ArticleTile>
    }

    const openArticle = (article: PubMedArticle) => {
        setCurrentArticle(article);
    }

    const closeArticle = () => {
        setCurrentArticle(null);
    }

    const excludeArticle = async (article: PubMedArticle) => {

        const event: ExcludeArticle = {
            id: `${project.id}_${article.id}`,
            kind: 'ExcludeArticle',
            project_id: project.id,
            date: moment().toISOString(),
            pmid: article.pmid
        };
        await AlchemixFlux.postEvent("medscrib", event);
        loadProjectArticles();
    }

    const addToCollection = async (article: PubMedArticle) => {

        const event: IncludeArticle = {
            id: `${project.id}_${article.id}`,
            kind: 'IncludeArticle',
            project_id: project.id,
            date: moment().toISOString(),
            pmid: article.pmid
        };
        await AlchemixFlux.postEvent("medscrib", event);
        loadProjectArticles();
    }

    const loadProjectArticles = async () => {
        const event: LoadProjectArticles = {
            id: generateUUID(),
            kind: "LoadProjectArticles",
            project_id: project.id
        };
        const res = await AlchemixFlux.postEvent("medscrib", event);
        const articles: ProjectArticles = res.getFirst("ProjectArticles");

        if (articles) {
            setProjectArticles(articles);
        }
    }

    const isArticleIncluded = (article: PubMedArticle, projectArticles: ProjectArticles) => {
        let res = !!projectArticles?.selected_articles?.find(ex => ex.pmid === article.id);
        return res;
    }

    const isArticleExluded = (article: PubMedArticle, projectArticles: ProjectArticles) => {
        let res = !!projectArticles?.excluded_articles?.find(ex => ex.pmid === article.id);
        return res;
    }

    const removeFromCollection = async (article: PubMedArticle) => {
        // const rxName = "pubmed_search";
        // await AlchemixRx.deleteEntities(rxName, "IncludeArticle", [`${project.id}_${article.id}`]);
        // loadProjectArticles();
    }

    const removeFromExclusion = async (article: PubMedArticle) => {
        // const rxName = "pubmed_search";
        // await AlchemixRx.deleteEntities(rxName, "ExcludeArticle", [`${project.id}_${article.id}`]);
        // loadProjectArticles();
    }

    const listColumnStyle = currentArticle ? '' : 'flex-1';

    const titleStyle = currentArticle ? 'flex-col' : '';

    const refreshButtonStyle = currentArticle ? 'flex-1' : '';

    const refreshSearch = () => {
        onRefreshSearch()
    }

    const executeSelectionAction = (article: PubMedArticle) => (action: SelectionAction) => {
        if (action.action === 'Search') {

        } else if (action.action === 'RefineSearch') {
            onRefineSearch(action.selectedText);
        } else if (action.action === 'Bookmark') {
            addBookmark(article, action.selectedText);
        }
    }

    const addBookmark = (article: PubMedArticle, selectedText: string) => {
        console.log("Add bookmark : ", article.id, selectedText);
        
    }

    return <div className='flex-1 overflow-hidden flex flex-col p-2'>
        {
            (searchResults || loading) &&
            <div className="flex-1 flex overflow-hidden">
                <div className={`overflow-hidden w-4/12 ${listColumnStyle} flex flex-col items-center px-2`}>
                    {
                        searchResults && !loading && <div className={`flex  ${titleStyle} my-1 w-full max-w-screen-md`}>
                            <ScreenTitle level="secondary" className={`flex-1`}>
                                <span className="flex-1 flex space-x-2">
                                    <div className={`flex flex-col font-thin ${refreshButtonStyle}`}>
                                        <span>Search Results</span>
                                        <span className="text-xs opacity-75">Last execution: {moment(searchResults.date).fromNow()}</span>
                                    </div>

                                    <button className="btn btn-ghost btn-circle btn-xs opacity-50"
                                        onClick={refreshSearch}>
                                        <ResetIcon></ResetIcon>
                                    </button>
                                </span>

                            </ScreenTitle>
                            <div className="text-xs opacity-75 font-thin">Total Results: {searchResults.total_count}</div>
                        </div>
                    }
                    <List
                        className="max-w-screen-md"
                        hideBorders={true}
                        loading={loading || loadingResults}
                        items={visibleDocuments}
                        renderItem={articleTileRenderer(openArticle, currentArticle, projectArticles)}
                        emptyMessage={'No Results available'}
                        emptyMessageDetails={''}
                        loadingMessage="Searching for documents"
                    ></List>
                </div>
                {
                    currentArticle && <div className="flex-1 flex flex-col items-center overflow-hidden">
                        <ArticleScreen
                            queryTerms={searchResults.terms_translation}
                            article={currentArticle}
                            isExcluded={isArticleExluded(currentArticle, projectArticles)}
                            isIncluded={isArticleIncluded(currentArticle, projectArticles)}
                            onClose={closeArticle}
                            onAddToCollection={addToCollection}
                            onRemoveFromCollection={removeFromCollection}
                            onRemoveFromExlusion={removeFromExclusion}
                            onExclude={excludeArticle}
                            onSelectionAction={executeSelectionAction(currentArticle)}></ArticleScreen>
                    </div>
                }

            </div>
        }

        {
            !searchResults && <div className="flex-1 flex overflow-hidden mt-4">

            </div>
        }

    </div>
}