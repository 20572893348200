import { Author } from '@medscrib-core/Author'
import * as React from 'react'

type Props = {
    authors: Author[]
} & React.HTMLAttributes<HTMLDivElement>

export const AuthorsList: React.FC<Props> = ({ authors }: Props) => {

    return <>
        {
            authors && <div className="space-x-1 text-xs opacity-75">
                {
                    authors.map((author, index) => {
                        const isLast = index == (authors.length - 1);
                        const comma = isLast ? '' : ','
                        return <span key={author.id}>{author.last_name} {author.initials}{comma}</span>
                    })
                }

            </div>
        }
    </>
}