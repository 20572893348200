import * as React from 'react'

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const DrillDownIcon: React.FC<Props> = ({ className }: Props) => {

    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
        className={`h-4 w-4 ${className}`}>
        <path d="M10 18H14V16H10V18ZM3 6V8H21V6H3ZM6 13H18V11H6V13Z"></path>
    </svg>
}