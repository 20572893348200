import { motion } from 'framer-motion';
import * as React from 'react'

type Props = {
    visible: boolean,
    message?: string
} & React.HTMLAttributes<HTMLDivElement>


const bounceTransition = {
    duration: 0.8,
    repeat: Infinity,
    ease: 'easeInOut',
};

export const ProgressIndicator: React.FC<Props> = ({ visible, message }: Props) => {

    return <div className='flex justify-center w-full'>
        {
            visible && <div className='flex flex-col items-center'>
                <div className="flex justify-around items-center w-12 h-6 opacity-50 m-4 mt-8"
                >
                    <motion.div
                        className="w-3 h-3 bg-base-content rounded-full"
                        transition={bounceTransition}
                        animate={{
                            translateY: ["75%", "-75%", "75%"]
                        }}
                    />
                    <motion.div
                        className="w-3 h-3 bg-base-content rounded-full"
                        transition={{ ...bounceTransition, delay: 0.3 }}
                        animate={{
                            translateY: ["75%", "-75%", "75%"]
                        }}
                    />

                    <motion.div
                        className="w-3 h-3 bg-base-content rounded-full"
                        transition={{ ...bounceTransition, delay: 0.6 }}
                        animate={{
                            translateY: ["75%", "-75%", "75%"]
                        }}
                    />
                </div>
                <div className='font-thin opacity-70 mt-2 italic text-sm'>{message}</div>
            </div>
        }
    </div>
}