import * as React from 'react'

import { motion } from 'framer-motion';

import { useNavigate } from 'react-router-dom';

import { MainPage } from '@sleekly-ui/layout/MainPage';
import { AppState } from '@sleekly-ui/AppState';
import { ServerApi, ServerResponse } from '@sleekly-ui/ServerApi';

export type UserAuth = { full_name: string, token: string, roles: Array<string>, id: string, kind: string, };

export type LoginText = {
    userName: string,
    password: string,
    stayConnected: string,
    login: string
}

type Props = {
    defaultRoute: string,
    text?: LoginText
} & React.HTMLAttributes<HTMLDivElement>

export const Login: React.FC<Props> = ({ defaultRoute, children, text }: Props) => {

    const [user, setUser] = AppState.useState<UserAuth>("current-user", null);

    const [username, setUsername] = React.useState<string>('');

    const [password, setPassword] = React.useState<string>('');

    const [stayConnected, setStayConnected] = React.useState<boolean>(true);

    const [shake, setShake] = React.useState(false);


    const navigate = useNavigate();

    const currentText: LoginText = text ?? {
        userName: "Username",
        password: "Password",
        stayConnected: "Stay connected",
        login: "Login"
    }

    React.useEffect(() => {
        if (!user) {
            const storedUser = loadUser();
            setUser(storedUser);
        }
    }, []);

    React.useEffect(() => {
        if (user) {
            navigate(defaultRoute);
        }
    }, [user]);

    const performLogin = async () => {
        if (username && password) {
            const response: ServerResponse<UserAuth> = await ServerApi.postJson('/api/login', {
                login: username,
                password
            });

            if (response.status === 200) {
                const userProfile = response.data;
                setUser(userProfile);
                if (stayConnected) {
                    const userJSON = JSON.stringify(userProfile);
                    localStorage.setItem('current-user', userJSON);
                }
            } else if (response.status === 403) {
                // Failed
                setShake(true);
                setTimeout(() => {
                    setShake(false);
                }, 1000);

            } else {
                console.log(response.status, response.statusText);
            }
        }
    }

    const captureEnter = (event) => {
        if (event.key === "Enter") {
            // Cancel the default action, if needed
            event.preventDefault();
            performLogin();
        }
    }

    return <MainPage className='items-center justify-center p-4'>

        <motion.div className='flex flex-col p-4 rounded-md bg-base-200 w-full max-w-md'
            animate={shake ? { x: [0, -10, 10, -10, 10, 0] } : {}}
            transition={{ duration: 0.5 }}
            onKeyDown={captureEnter}>

            {children}

            <label className='flex flex-col space-y-1 my-1'>
                <span className='text-xs'>{currentText.userName}</span>
                <input className='input input-sm'
                    value={username}
                    onChange={e => setUsername(e.target.value)}></input>
            </label>
            <label className='flex flex-col space-y-1 my-1'>
                <span className='text-xs'>{currentText.password}</span>
                <input className='input input-sm'
                    type='password'
                    value={password}
                    onChange={e => setPassword(e.target.value)}></input>
            </label>

            <label className="cursor-pointer label">
                <span className="label-text">{currentText.stayConnected}</span>
                <input type="checkbox" className="checkbox checkbox-sm"
                    checked={stayConnected}
                    onChange={e => setStayConnected(e.target.checked)} />
            </label>

            <div className='flex justify-center mt-4'>
                <button className='btn btn-sm btn-accent'
                    onClick={performLogin}
                    disabled={username.length === 0 || password.length === 0}>{currentText.login}</button>
            </div>

        </motion.div>
    </MainPage>
}

export function logOut() {
    localStorage.setItem('current-user', null);
    AppState.pushState("current-user", null);
}


export function loadUser(): UserAuth {
    const userJSON = localStorage.getItem('current-user');
    if (userJSON) {
        const storedUser = JSON.parse(userJSON);
        return storedUser;
    } else {
        return null
    }
}

export function getCurrentUser(): UserAuth {
    return AppState.getState("current-user");
}


export function useLoggedInUser() {
    const navigate = useNavigate();
    let savedUser = loadUser();
    let currentUser = AppState.getState<UserAuth>("current-user");
    if (!currentUser && savedUser) {
        refreshToken(savedUser);
    }
    const [user] = AppState.useState<UserAuth>("current-user", savedUser);
    React.useEffect(() => {
        if (!user) {
            navigate("/login");
        }
    }, [user]);
    return user;
}

export async function refreshToken(user: UserAuth): Promise<UserAuth> {
    const response: ServerResponse<UserAuth> = await ServerApi.postJson('/api/refresh_token', null, user.token);

    if (response.status === 200) {
        const userProfile = response.data;
        AppState.pushState("current-user", userProfile);
        const userJSON = JSON.stringify(userProfile);
        localStorage.setItem('current-user', userJSON);
        return userProfile;
    } else if (response.status === 403) {
        logOut();
    } else {
        console.log(response.status, response.statusText);
    }
}