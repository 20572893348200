import * as React from "react";

import { AlchemixFlux } from "../alchemix/Alchemix";

import { SearchInput } from '@sleekly-ui/components/SearchInput';

import { RunSearch } from "@medscrib-core/RunSearch";
import { SearchResultsSummary } from "@medscrib-core/SearchResultsSummary";
import { Project } from "@medscrib-core/Project";
import { generateUUID } from "@sleekly-ui/utils/uuid";
import { SearchResultsExplorer } from "./SearchResultsExplorer";
import { SearchHistory } from "./SearchHistory";
import { SearchEntrySummary } from "@medscrib-core/SearchEntrySummary";
import { ChevronRightIcon, DrillDownIcon } from "@sleekly-ui/icons";


type Props = {
    project: Project
} & React.HTMLAttributes<HTMLDivElement>

export const SearchPage: React.FC<Props> = ({ project }: Props) => {

    const [searchResults, setSearchResults] = React.useState<SearchResultsSummary>(null);

    const [loading, setLoading] = React.useState<boolean>(false);

    const [query, setQuery] = React.useState<string>('');

    React.useEffect(() => {

    }, [project]);

    const executeSearch = async (terms: string) => {
        executeSearchWithId(`${project.id}-${generateUUID()}`, terms, true);
    }

    const executeSearchWithId = async (id, terms: string, refresh?: boolean) => {
        const search: RunSearch = {
            id,
            kind: 'RunSearch',
            project_id: project.id,
            terms: terms,
            refresh: !!refresh,
            parent_search_id: "",
            refined_terms: []
        };
        runSearchAction(search);
    }

    const runSearchAction = async (search: RunSearch) => {
        setLoading(true);
        const res = await AlchemixFlux.postEvent("medscrib", search);
        const results: SearchResultsSummary = res.getFirst("SearchResultsSummary");
        console.log(res);
        setSearchResults(results);
        setLoading(false);
    }

    const refineSearch = async (terms: string) => {
        const search: RunSearch = {
            id: generateUUID(),
            kind: 'RunSearch',
            project_id: project.id,
            terms: searchResults.terms,
            refresh: true,
            parent_search_id: searchResults.search_id,
            refined_terms: [].concat(searchResults.refined_terms, terms)
        };
        runSearchAction(search);
    }

    const runFromHistory = async (searchHistoryEntry: SearchEntrySummary) => {
        setQuery(searchHistoryEntry.terms);
        await executeSearchWithId(searchHistoryEntry.id, searchHistoryEntry.terms, false);
    }

    const refreshSearch = async () => {
        await executeSearchWithId(searchResults.id, searchResults.terms, true);
    }

    return <div className='flex-1 overflow-hidden flex flex-col p-2'>

        <div className='flex justify-center my-4 w-full'>
            <SearchInput query={query} onPerformQuery={executeSearch} className='max-w-screen-md'
                buttonLabel="Search"></SearchInput>

        </div>
        <div className='flex justify-center w-full'>
            <div className="w-full max-w-screen-md">
                {
                    searchResults && <div className="flex items-center">
                        {
                            searchResults.refined_terms.length > 0 && <DrillDownIcon></DrillDownIcon>
                        }
                        {
                            searchResults.refined_terms.map((term, index) => {
                                const isLast = index === (searchResults.refined_terms.length - 1);
                                return <div key={term} className="flex items-center">
                                    <div
                                        className="badge badge-neutral flex space-x-2 mx-1 py-2 cursor-pointer hover:opacity-75">
                                        <span>{term}</span>
                                    </div>
                                    {
                                        !isLast && <ChevronRightIcon></ChevronRightIcon>
                                    }
                                </div>
                            })
                        }
                    </div>
                }
            </div>
        </div>

        {
            (searchResults || loading) && <SearchResultsExplorer project={project}
                searchResults={searchResults}
                loading={loading}
                onRefreshSearch={refreshSearch}
                onRefineSearch={refineSearch}></SearchResultsExplorer>
        }
        {
            !(searchResults || loading) && <div className='flex-1 flex flex-col items-center my-4 w-full'>
                <SearchHistory project={project} onPerformSearch={runFromHistory}></SearchHistory>
            </div>
        }
    </div>
}