import * as React from "react";
import * as moment from "moment";

import { Project } from "@medscrib-core/Project";
import { MoreVertIcon, StackIcon } from "@sleekly-ui/icons";
import { useFloating } from "@floating-ui/react";
import { MenuItem } from "@sleekly-ui/components/MenuItem";

type Props = {
    project: Project,
    onOpenProject: (project: Project) => void
} & React.HTMLAttributes<HTMLDivElement>

export const ProjectCard: React.FC<Props> = ({ project, onOpenProject }: Props) => {

    const [isOpen, setIsOpen] = React.useState(false);

    const { refs, floatingStyles } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
    });

    const refLabel = project.references_count > 1 ? "References" : "Reference";

    const openProject = (project: Project) => () => {
        onOpenProject && onOpenProject(project);
    }

    const openMenu = (e) => {
        setIsOpen((state) => !state);
        e.stopPropagation();
        e.preventDefault();
    }

    const deleteProject = (e) => {
        setIsOpen(false);
        e.stopPropagation();
        e.preventDefault();
    }

    return <div key={project.id} className="flex flex-col bg-base-300 rounded-lg p-4 cursor-pointer h-36 hover:bg-opacity-60"
        onClick={openProject(project)}>
        <div className="flex-1 flex items-center">
            <StackIcon className="w-6 h-6"></StackIcon>
            <div className="flex-1"></div>
            <button ref={refs.setReference} className='btn btn-xs btn-ghost btn-circle'
                onClick={openMenu}>
                <MoreVertIcon></MoreVertIcon>
            </button>
            {
                isOpen && <div ref={refs.setFloating} style={floatingStyles}>
                    <div className="shadow-lg bg-base-100 p-2 w-72 rounded-lg mt-4">
                        <MenuItem onClick={deleteProject}>Delete</MenuItem>
                    </div>
                </div>
            }
        </div>
        <div className="text-2xl my-2">{project.name}</div>
        <div className="flex font-thin">
            <div className="text-sm">{moment(project.date).format('DD MMM YYYY - HH:mm')}</div>
            <div className="mx-2">°</div>
            <div className="text-sm">{project.references_count} {refLabel}</div>
        </div>
    </div>
}