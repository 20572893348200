

export namespace AlchemixFlux {

    export async function postEvent(fluxName: string, payload: any):Promise<FluxResponse> {
        const authToken = "";
        const body = JSON.stringify(payload);
        const endpoint = `/api/flux/${fluxName}/event`
        const response: any = await postJson(endpoint, authToken, body);
        return new FluxResponse(response);
    }

    export async function queryEntities<T>(fluxName: string, shard: string, kind: string, property: string, expression: string):Promise<T[]> {
        const authToken = "";

        const payload = {
            shard,
            kind,
            property_name: property,
            expr: expression,
        };

        const body = JSON.stringify(payload);
        const endpoint = `/api/flux/${fluxName}/query`
        const response: T[] = await postJson(endpoint, authToken, body) as T[];
        return response;
    }

    export async function getEntities<T>(fluxName: string, shard: string, kind: string, ids: string[]):Promise<T[]> {
        const authToken = "";

        const payload = {
            shard,
            kind,
            ids
        };

        const body = JSON.stringify(payload);
        const endpoint = `/api/flux/${fluxName}/entities`
        const response: T[] = await postJson(endpoint, authToken, body) as T[];
        return response;
    }

    async function postJson(endpoint: string, authToken: string, body: string): Promise<any> {
        const resp = await fetch(endpoint, {
            method: 'POST',
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "Token": authToken
            },
            body: body
        });
        return await resp.json();
    }

}

export class FluxResponse {
    response: any[]

    constructor(response: any[]){
        this.response = response
    }

    getAll<T>(kind: string):T[]{
        const entities = this.response?.map(hookResponse => {
            const entities = hookResponse.entities?.filter(entity => entity.kind === kind);
            return entities;
        }).reduce((acc: T[], entities: T[]) => {
            return acc.concat(entities);
        }, []);
        return entities;
    }

    getFirst<T>(kind: string):T{
        const entities:T[] = this.getAll(kind);
        return entities[0];
    }
}