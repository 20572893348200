import * as React from 'react'
import { Dialog } from '@sleekly-ui/layout/Dialog'
import { PubMedArticle } from '@medscrib-core/PubMedArticle';
import { CloseIcon } from '@sleekly-ui/icons';
import { ScreenTitle } from '@sleekly-ui/components/ScreenTitle';
import { ArticleView } from '../article/ArticleView';
type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const DOCUMENT_CONTENT_DIALOG = "DOCUMENT_CONTENT_DIALOG";

export const DocumentContentDialog: React.FC<Props> = ({ }: Props) => {


    const dialogContentRenderer = (article: PubMedArticle, close) => {
        return <div className='flex-1 flex flex-col overflow-hidden'>
            <ScreenTitle level='secondary'>
                <span>Article</span>
                <div className='flex-1'></div>
                <button onClick={close}>
                    <CloseIcon></CloseIcon>
                </button>
            </ScreenTitle>
            <ArticleView article={article} ></ArticleView>
        </div>
    }

    return <Dialog dialogId={DOCUMENT_CONTENT_DIALOG}
        renderContent={dialogContentRenderer}
        className='w-full max-w-screen-md'>
    </Dialog>
}