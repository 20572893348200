import * as React from "react";
import * as moment from "moment";

import { generateUUID } from "@sleekly-ui/utils/uuid";
import { ScreenTitle } from "@sleekly-ui/components/ScreenTitle";
import { AddIcon } from "@sleekly-ui/icons/AddIcon";

import { Project } from "@medscrib-core/Project";
import { UserProjects } from "@medscrib-core/UserProjects";

import { GetProjects } from "@medscrib-core/GetProjects";
import { ProjectCard } from "./ProjectCard";
import { AlchemixFlux } from "../alchemix/Alchemix";
import { useLoggedInUser } from "../alchemix/Login";


type Props = {
    onOpenProject: (project: Project) => void
} & React.HTMLAttributes<HTMLDivElement>

export const ProjectsPage: React.FC<Props> = ({ onOpenProject }: Props) => {

    const user = useLoggedInUser();

    const [projects, setProjects] = React.useState<Project[]>([]);

    React.useEffect(() => {
        if(user) {
            loadProjects();
        }
    }, [user]);

    const loadProjects = async () => {
        const event: GetProjects = {
            id: generateUUID(),
            kind: "GetProjects",
            owner: user.id
        };
        const res = await AlchemixFlux.postEvent("medscrib", event);
        const userProjects: UserProjects =res.getFirst("UserProjects");        
        
        // const projects = await AlchemixFlux.getEntities("medscrib", "projects", "Project", []);
        // console.log("all projects : ", projects);
        
        if(userProjects?.projects) {
            setProjects(userProjects.projects);    
        }
    }

    const newProject = async () => {
        const project: Project = {
            id: generateUUID(),
            kind: "Project",
            name: "Untitled Project",
            description: "",
            owner: user.id,
            references_count: 0,
            date: moment().toISOString()
        };
            
        await AlchemixFlux.postEvent("medscrib", project);
        onOpenProject(project);
    }

    return <div className="flex-1 p-4 flex flex-col overflow-hidden items-center">
        <div className="flex-1 flex flex-col w-full max-w-screen-lg overflow-hidden">
            <div className="text-4xl mt-8 py-4 font-bold leading-snug">Welcome to your <div className="text-accent text-6xl leading-relaxed">Medical Research Assistant</div></div>
            <ScreenTitle>My Projects</ScreenTitle>
            <div className="border-b border-base-content border-opacity-40 mb-2"></div>
            <div className="flex mb-2">
                <button className="btn btn-sm btn-accent"
                    onClick={newProject}>
                    <AddIcon></AddIcon>
                    <span>New</span>
                </button>
            </div>

            <div className="my-2 flex-1 overflow-auto">
                <div className="grid grid-cols-3 gap-4">
                    {
                        projects.map(project => {
                            return <ProjectCard key={project.id}
                                project={project}
                                onOpenProject={onOpenProject}>
                            </ProjectCard>
                        })
                    }
                </div>
            </div>
        </div>
    </div>
}