import * as React from "react";

import { Dialog } from "@sleekly-ui/layout/Dialog";
import { Project } from "@medscrib-core/Project";
import { ScreenTitle } from "@sleekly-ui/components/ScreenTitle";

import { CloseIcon, StackIcon } from "@sleekly-ui/icons"
import { AlchemixFlux } from "../alchemix/Alchemix";
import { AppState } from "@sleekly-ui/AppState";

import { CURRENT_PROJECT } from "../UserHome";

type Props = {
} & React.HTMLAttributes<HTMLDivElement>

export const PROJECT_DIALOG = "PROJECT_DIALOG";

export const ProjectDialog: React.FC<Props> = ({}: Props) => {

    const [project, setCurrentProject] = AppState.useState<Project>(CURRENT_PROJECT);
    
    const dialogContentRenderer = (project, setCurrentProject) => (_project: Project, close) => {

        const update = async (prop: string, value: string) => {
            const copy = Object.assign({}, project);
            copy[prop] = value;
            setCurrentProject(copy);
            await AlchemixFlux.postEvent("medscrib", copy);
        }

        return <div>
            <ScreenTitle>
                <StackIcon className="w-8 h-8"></StackIcon>
                <div className='flex-1'>Project</div>
                <button className='btn btn-sm btn-circle btn-ghost focus:outline-none'
                    onClick={() => close()}>
                    <CloseIcon></CloseIcon>
                </button>
            </ScreenTitle>

            <div className='max-w-2xl flex-1 w-full overflow-auto p-2'>
                <div className='text-xs pb-1 opacity-50'>Project Name *</div>
                <input className='input input-bordered w-full'
                    value={project?.name}
                    placeholder='Name'
                    onChange={(e) => update('name', e.target.value)}></input>

                <div className='text-xs pb-1 opacity-50 my-2 mt-4'>Description</div>
                <textarea className="textarea textarea-bordered w-full"
                    placeholder="Short description"
                    rows={7}
                    value={project?.description}
                    onChange={(e) => update('description', e.target.value)}></textarea>
            </div>
        </div>
    }

    return <Dialog dialogId={PROJECT_DIALOG}
        renderContent={dialogContentRenderer(project, setCurrentProject)}
        className="w-full max-w-screen-xl">
    </Dialog>
}

