import { BookmarkIcon, CommentIcon, DrillDownIcon } from '@sleekly-ui/icons'
import { SearchIcon } from '@sleekly-ui/icons/SearchIcon'
import * as React from 'react'

type Props = {
    onAction: (action: string) => void;
} & React.HTMLAttributes<HTMLDivElement>

export const TextSelectionMenu:React.FC<Props> = ({onAction}: Props) => {

    const executeAction = (action:string) => (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        onAction(action);
    }

    return <div className="shadow-lg bg-base-100 p-2 m-2 rounded-lg mt-4 flex flex-col space-y-1">
    <button className="btn btn-sm justify-start" onClick={executeAction("Bookmark")}>
        <BookmarkIcon></BookmarkIcon>
        <span>Bookmark</span>
    </button>
    <button className="btn btn-sm justify-start" onClick={executeAction("RefineSearch")}>
        <DrillDownIcon></DrillDownIcon>
        <span>Refine Search</span>
    </button>
    <button className="btn btn-sm justify-start" onClick={executeAction("Search")}>
        <SearchIcon></SearchIcon>
        <span>New Search</span>
    </button>
</div>
}