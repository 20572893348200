import { Journal } from '@medscrib-core/Journal'
import * as React from 'react'

type Props = {
    journal: Journal
} & React.HTMLAttributes<HTMLDivElement>

export const JournalName: React.FC<Props> = ({ journal }: Props) => {

    return <>
        {
            journal && <div className="space-x-1 text-sm opacity-75 mb-1">
                {journal.title} - {journal.pub_date} - Vol. {journal.volume}
            </div>
        }
    </>
}