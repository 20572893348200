import * as React from "react"

type Props = {
    text: string,
    kind: string,
    uid: string
} & React.HTMLAttributes<HTMLDivElement>

export const KeywordHighlight: React.FC<Props> = ({text, kind}: Props) => {
    const styles = {
        'query_term' : 'text-accent'
    };
    const style = styles[kind] ? styles[kind] : 'underline';

    return <span className={`inline-block cursor-pointer ${style}`}>
        {text}
    </span>
}