import * as React from "react"
import { createRoot } from "react-dom/client"
import { KeywordHighlight } from "./KeywordHighlight"
import { useFloating } from "@floating-ui/react";
import { TextSelectionMenu } from "./TextSelectionMenu";

export type SelectionAction = {
    action: string,
    selectedText: string
}

type Rect = {
    x: number; y: number; width: number; height: number
}

type Props = {
    text: string,
    onSelectionAction?: (action: SelectionAction ) => void
} & React.HTMLAttributes<HTMLDivElement>

export const TextContent: React.FC<Props> = ({ text, className, onSelectionAction }: Props) => {

    const [rect, setRect] = React.useState<Rect>(null);

    const [selectedText, setSelectedText] = React.useState<string>('');

    const currentEl = React.useRef<HTMLDivElement>(null);

    const floatingContainer = React.useRef<HTMLDivElement>(null);

    

    React.useEffect(() => {
        window.document.addEventListener('mousedown', onClickOnDocument);
        return () => {
            window.document.removeEventListener('mousedown', onClickOnDocument);
        };
    }, []);

    const onClickOnDocument = (e) => {    
        if ( !floatingContainer.current?.contains(e.target)) {
            clearSelection();
        }
    }

    const renderKeywords = (el: HTMLDivElement) => {
        if (el) {
            const keywords = el.querySelectorAll('span.keyword');
            keywords.forEach(k => {
                const uid = k.getAttribute("uid");
                const kind = k.getAttribute("kind");
                const text = k.textContent;
                const container = document.createElement('span');
                k.replaceWith(container);
                createRoot(container).render(<KeywordHighlight text={text} kind={kind} uid={uid} />)
            })
        }
    }

    const handleSelect = (event: any) => {
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
            const selectedText = window.getSelection()?.toString() || '';
            setSelectedText(selectedText);
            const range = selection.getRangeAt(0);
            const rect = range.getBoundingClientRect();
            setRect({ x: event.clientX, y: rect.y, width: 20, height: rect.height });
        }
    };

    const clearSelection = () => {
        window.getSelection().removeAllRanges();
        setRect(null);
        setSelectedText(null);
    }

    const { refs, floatingStyles } = useFloating({
        open: !!selectedText,
        placement: 'top',
    });

    const onAction = (action: string) => {
        onSelectionAction && onSelectionAction({
            action,
            selectedText
        })
        clearSelection();
    }

    return <div ref={renderKeywords}
        onMouseUp={handleSelect}
        className={`${className ?? ''}`} >
        <div ref={currentEl} dangerouslySetInnerHTML={{ __html: text }}></div>
        {
            rect && selectedText && <div ref={refs.setReference}
                style={{ position: 'absolute', top: rect.y, left: rect.x, width: rect.width, height: rect.height }}>
            </div>
        }
        {
            onSelectionAction && selectedText && <div ref={refs.setFloating} style={floatingStyles}>
                <div ref={floatingContainer}>
                    <TextSelectionMenu onAction={onAction}></TextSelectionMenu>
                </div>
            </div>
        }
    </div>
}