import * as React from 'react'

import { ProgressIndicator } from "@sleekly-ui/components/ProgressIndicator";
import { AuthorsList } from "./AuthorsList";
import { JournalName } from "./JournalName";

import { ExpandIcon } from "@sleekly-ui/icons/ExpandIcon";
import { SelectionAction, TextContent } from "./TextContent";
import { FileTextIcon, MagicIcon } from "@sleekly-ui/icons";
import { PubMedArticle } from '@medscrib-core/PubMedArticle';
import { AlchemixFlux } from '../alchemix/Alchemix';
import { ArticleSummary } from '@medscrib-core/ArticleSummary';
import { GetArticleSummary } from '@medscrib-core/GetArticleSummary';
import * as moment from 'moment';


type Props = {
    article: PubMedArticle,
    onSelectionAction?: (action: SelectionAction) => void
} & React.HTMLAttributes<HTMLDivElement>

export const ArticleView: React.FC<Props> = ({ article, onSelectionAction }: Props) => {

    const [contentDisplayMode, setContentDisplayMode] = React.useState<string>("FullText");

    const [loading, setLoading] = React.useState<boolean>(false);

    const [summary, setSummary] = React.useState<ArticleSummary>(null);

    const showFullText = () => {
        setContentDisplayMode("FullText");
    }

    const textModeStyle = (action: string, contentDisplayMode: string) => {
        return action === contentDisplayMode ? 'btn-primary' : ''
    }

    React.useEffect(() => {
        if (!(summary?.id === article.pmid)) {
            setContentDisplayMode("FullText");
            setSummary(null);
        }
    }, [article])

    const showSummary = async () => {
        setContentDisplayMode("Summary");
        if (!summary) {
            setLoading(true);
            const event: GetArticleSummary = {
                id: article.id,
                kind: 'GetArticleSummary',
                article,
                force_update: false,
                terms: []
            };

            const res = await AlchemixFlux.postEvent("medscrib", event);
            const summary: ArticleSummary = res.getFirst("ArticleSummary");

            setLoading(false);
            setSummary(summary);
        }
    }


    return <div className="flex-1 flex flex-col select-text overflow-auto">
        <div className="mb-2 flex">
            <TextContent className="text-lg font-bold" text={article.title} onSelectionAction={onSelectionAction}></TextContent>
            <a className="btn btn-ghost btn-square btn-xs ml-2"
                href={`https://pubmed.ncbi.nlm.nih.gov/${article.id}/`}
                target="_blank">
                <ExpandIcon></ExpandIcon>
            </a>
        </div>
        <JournalName journal={article.journal}></JournalName>
        <AuthorsList authors={article.authors}></AuthorsList>

        <div className="flex space-x-2">
            <button className={`btn btn-xs my-2 ${textModeStyle('Summary', contentDisplayMode)}`} onClick={showSummary}>
                <MagicIcon></MagicIcon>
                <span>Main Topic</span>
            </button>
            <button className={`btn btn-xs my-2 ${textModeStyle('FullText', contentDisplayMode)}`} onClick={showFullText}>
                <FileTextIcon></FileTextIcon>
                <span>Full Text</span>
            </button>
        </div>

        <ProgressIndicator visible={loading} message="Summarizing article content"></ProgressIndicator>
        {
            contentDisplayMode === "Summary" && summary && <div>
                <TextContent text={summary.main_topic} onSelectionAction={onSelectionAction}></TextContent>
                <TextContent text={summary.conclusion} onSelectionAction={onSelectionAction}></TextContent>
                <div className='text-xs opacity-75 text-right'>Generated {moment(summary.date).fromNow()}</div>
            </div>
        }
        {
            contentDisplayMode === "FullText" && <>
                <TextContent text={article.summary} onSelectionAction={onSelectionAction}></TextContent>
            </>
        }
    </div>
}