import * as React from "react";

import { PubMedArticle } from "@medscrib-core/PubMedArticle";
import { ScreenTitle } from "@sleekly-ui/components/ScreenTitle";
import { SelectionAction } from "./TextContent";
import { AddIcon } from "@sleekly-ui/icons/AddIcon";
import { CloseIcon, DeleteIcon } from "@sleekly-ui/icons";
import { TermTranslation } from "@medscrib-core/TermTranslation";

import { ArticleView } from "./ArticleView";

type Props = {
    queryTerms: TermTranslation[]
    article: PubMedArticle,
    onClose: () => void,
    isExcluded: boolean,
    isIncluded: boolean,
    onExclude: (article: PubMedArticle) => void,
    onAddToCollection: (article: PubMedArticle) => void,
    onRemoveFromCollection: (article: PubMedArticle) => void,
    onRemoveFromExlusion: (article: PubMedArticle) => void,
    onSelectionAction?: (action: SelectionAction) => void

} & React.HTMLAttributes<HTMLDivElement>

export const ArticleScreen: React.FC<Props> = ({ article,
    queryTerms,
    isExcluded,
    isIncluded,
    onClose,
    onExclude,
    onAddToCollection,
    onRemoveFromCollection,
    onRemoveFromExlusion,
    onSelectionAction }: Props) => {

   
    const excludeArticle = () => {
        onExclude && onExclude(article);
    }

    const addToCollection = () => {
        onAddToCollection && onAddToCollection(article);
    }

    const removeFromCollection = () => {
        onRemoveFromCollection && onRemoveFromCollection(article);
    }

    const removeFromExclusion = () => {
        onRemoveFromExlusion && onRemoveFromExlusion(article);
    }

    return <div className="w-full max-w-screen-md flex flex-col overflow-hidden">
        <ScreenTitle level="secondary" className="items-baseline">
            <span >Article</span>

            <span className="flex-1"></span>
            <div className="flex items-center">
                {
                    !isIncluded && !isExcluded && <button className="btn btn-xs btn-neutral ml-2" onClick={excludeArticle}>
                        <DeleteIcon></DeleteIcon>
                        <span>Discard</span>
                    </button>
                }
                {
                    !isIncluded && !isExcluded && <button className="btn btn-xs btn-accent ml-2" onClick={addToCollection}>
                        <AddIcon></AddIcon>
                        <span>Add to Collection</span>
                    </button>
                }

                {
                    isIncluded && <button className="btn btn-xs btn-neutral ml-2" onClick={removeFromCollection}>
                        <DeleteIcon></DeleteIcon>
                        <span>Remove from Collection</span>
                    </button>
                }

                {
                    isExcluded && <button className="btn btn-xs btn-neutral ml-2" onClick={removeFromExclusion}>
                        <DeleteIcon></DeleteIcon>
                        <span>Remove from Discard List</span>
                    </button>
                }

                <button className="btn btn-xs btn-circle btn-neutral ml-6" onClick={onClose}>
                    <CloseIcon></CloseIcon>
                </button>
            </div>
        </ScreenTitle>
        <ArticleView article={article} onSelectionAction={onSelectionAction}></ArticleView>
    </div>
}