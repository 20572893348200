import { PageSwitcher } from '@sleekly-ui/layout/PageSwitcher';
import * as React from 'react'
import { SearchPage } from '../search/SearchPage';
import { Project } from '@medscrib-core/Project';
import { DocumentCollection } from '../collection/DocumentCollection';

type Props = {
    project: Project
} & React.HTMLAttributes<HTMLDivElement>

export const ProjectHome: React.FC<Props> = ({ project }: Props) => {

    const [currentPage, setCurrentPage] = React.useState<string>("Search");

    return <>
        <div>
            <button className='btn' onClick={() => setCurrentPage("Search")}>Search</button>
            <button className='btn' onClick={() => setCurrentPage("Collection")}>Selected Articles</button>
        </div>
        <PageSwitcher activePageId={currentPage} pages={[
            {
                id: "Search",
                render: () => <SearchPage project={project}></SearchPage>
            },
            {
                id: "Collection",
                render: () => <DocumentCollection project={project}></DocumentCollection>
            }
        ]}>
        </PageSwitcher>
    </>
}