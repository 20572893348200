import * as React from 'react'

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const ResetIcon: React.FC<Props> = ({ className }: Props) => {

    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
        className={`h-4 w-4 ${className}`}>
        <path d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2V4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 9.53614 5.11383 7.33243 6.86543 5.86492L9 8V2L3 2L5.44648 4.44656C3.33509 6.28002 2 8.9841 2 12Z"></path>
    </svg>
}